import { HomePage, TasksPage, ProfilePage, UploadPage, Help} from './pages';
import { withNavigationWatcher } from './contexts/navigation';
import Pwa from "./pages/pwa/pwa";

const routes = [
    {
        path: '/tasks',
        element: TasksPage
    },
    {
        path: '/profile',
        element: ProfilePage
    },
    {
        path: '/home',
        element: HomePage
    },
    {
        path: '/upload',
        element: UploadPage
    },
    {
        path: '/help',
        element: Help
    },
    {
        path: '/pwa',
        element: Pwa
    },
];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});
